@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AmericanTypewriter";
  src:
    local("AmericanTypewriter"),
    url("../public/AmericanTypewriter.ttf") format("truetype");
}

html {
  width: 100%;
  height: 100%;
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454545;
  background-color: #ffffff;
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.animation-delay-250 {
  animation-delay: 250ms;
}

.animation-delay-500 {
  animation-delay: 500ms;
}

.animation-delay-750 {
  animation-delay: 750ms;
}

.typewriter {
  font-family: "AmericanTypewriter", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input::placeholder,
textarea::placeholder {
  color: #c4c4c4;
  font-size: 20px;
  font-weight: 300;
}

.placeholder {
  padding-left: 8px;
  color: #c4c4c4;
  font-size: 20px;
  font-weight: 300;
}

/* selector for data-testid="input" */
div[data-testid="language"] ul {
  bottom: 36px;
}

.shadow-avatar {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.shadow-story-card {
  box-shadow: 0px 4px 14px 0px #0000001a;
}

.admin-layout {
  font-family: "Roboto", sans-serif;
}

.admin-layout input,
.admin-layout textarea {
  color: #000000;
  font-size: 16px;
}

.admin-layout input::placeholder,
.admin-layout textarea::placeholder {
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 500;
}

.admin-layout-dropdown span {
  font-size: 16px;
  font-weight: 500;
}

.debugger-font {
  font-family: "Source Code Pro", sans-serif;
}

.ribbon {
  font-size: 28px;
  font-weight: bold;
  color: #fff;
}

.ribbon {
  --r: 0.8em;
  padding-inline: calc(var(--r) + 0.3em);
  line-height: 1.8;
  clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
  width: fit-content;
}

.shadow-page {
  box-shadow:
    0 3px 2px -1px #00000040,
    inset 100px 0px 100px -50px #fdf2d5,
    inset -100px 0px 100px -50px #fdf2d5;
}

.shadow-avatar-bar {
  box-shadow: 0px 0px 24px 0px #00000040;
}

.shadow-avatar-bar-tab {
  box-shadow: 0px -6px 12px -2px #00000040;
}
